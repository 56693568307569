function updateTrianglePosition(link, overlay, triangle) {
  if (!overlay.is(':visible') || !triangle.is(':visible')) {
    return;
  }

  var posLink = link.offset().left,
      widthLink = link.width(),
      posOverlay = overlay.offset().left,
      widthTriangle = triangle.outerWidth(),
      targetPos;

  targetPos = posLink + (widthLink / 2);
  targetPos -= posOverlay;
  targetPos -= widthTriangle / 2;
  triangle.css({'right': 'auto', 'left': targetPos});
}

function updateAllTrianglePositions() {
  updateTrianglePosition($('a.toggle-cart'), $('#cart'), $('#cart .cart__triangle'));
  updateTrianglePosition($('a.toggle-newsletter'), $('#newsletter'),
                         $('#newsletter .cart__triangle'));
}

function validateFormInput($submit, $inputs) {
  if (!($submit.length && $inputs.length)) {
    return;
  }
  var valid = true;

  for (var i=0; i<$inputs.length; i++) {
    var $input = $($inputs[i]);
    if ($input.attr('name') != 'company' &&
        $input.attr('name') != 'street2' &&
        $input.attr('name') != 'checkout' &&
        $input.attr('name') != 'username' &&  // honeypot
        $input.attr('name') !== undefined) {
      if ($input.val() == '') {
        valid = false;
      }
    }
  }

  if (valid) {
     $submit.removeClass('disabled');
  } else {
     $submit.addClass('disabled');
  }
}

$(document).ready(function() {
  // close overlay
  $(document).click(function(e) {
    if ($(e.target).closest('#cart').length === 0 &&
        $(e.target).closest('.toggle-cart').length === 0 &&
        $(e.target).closest('#newsletter').length === 0 &&
        $(e.target).closest('.toggle-newsletter').length === 0 &&
        $(e.target).closest('#terms').length === 0) {
      $('#cart, #overlay, #terms, #newsletter').removeClass('open');
      $('.toggle-newsletter').removeClass('disabled');
    }
  });

  // toggle cart
  $('a.toggle-cart').on('click', function(ev) {
    ev.preventDefault();

    // is cart empty?
    if ($('#cart .cart__item').length == 0 &&
        $('#cart .cart__content__headline').length == 0) {
      return;
    }

    // do toggle
    if (!$('#cart:visible').length) {
      $('#newsletter, #terms').removeClass('open');
      $('.toggle-newsletter').removeClass('disabled');
      $('#cart, #overlay').addClass('open');
      updateAllTrianglePositions();
      $('html, body').animate({scrollTop: 0});
      var url = '/' + getLanguage() + '/cart/';
      trackPage(url, 'cart');
      trackEvent('cart-action', 'open');
    } else {
      if ($(document).scrollTop() <= 0) {
        $('#cart, #overlay, #terms, #newsletter').removeClass('open');
        trackPage(window.location.pathname, document.title);
        trackEvent('cart-action', 'close');
      } else {
        $('html, body').animate({scrollTop: 0});
      }
    }
  });

  // toggle newsletter
  $('a.toggle-newsletter').on('click', function(ev) {
    ev.preventDefault();
    if ($('a#close-menu').is(':visible')) {
      $('a#close-menu').click();
    }

    // do toggle
    if (!$('#newsletter:visible').length) {
      $('#cart, #terms').removeClass('open');
      $('#newsletter, #overlay').addClass('open');
      $('.toggle-newsletter').addClass('disabled');
      updateAllTrianglePositions();
      trackEvent('newsletter-action', 'open');
    } else {
      $('#cart, #overlay, #terms, #newsletter').removeClass('open');
      $('.toggle-newsletter').removeClass('disabled');
      trackEvent('newsletter-action', 'close');
    }
  });

  $(window).smartresize(updateAllTrianglePositions);
});
